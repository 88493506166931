.dashboard_container {
  overflow-y: hidden;
  overflow-x: hidden;
}

.hero_heading {
    font-size: clamp(40px, 5vw, 80px);
    font-family: "Koh Santepheap", serif;
    line-height: clamp(47px, 7vw, 85px);
    font-weight: 300;
    transition: 0.3s;
    // letter-spacing: 90px;
    b{
        font-family: inherit; 
        font-weight: 400;
        background: radial-gradient(circle at 67% 100%, rgba(9, 144, 251, 1) 0%, rgba(93, 30, 196, 1) 86%, rgba(93, 30, 196, 1) 100%);
        -webkit-background-clip: text;
        color: transparent;
    }
    span{
        font-family: inherit; 
        display: inline-block;
    }
}
.responsive_nav {
    @apply lg:flex block items-center w-full lg:justify-between overflow-y-hidden lg:overflow-y-auto overflow-x-hidden;
    transition: 0.8s ease-in-out;
}

.hero_title {
    font-weight: 300;
    transition: 0.3s;
    @apply text-[16px] pt-3;
}

.ennroll_btn {
    padding: 0.8em 1.5em;
    transition: 0.3s;
    @apply text-[16px] rounded-lg w-[265px] my-2 mx-2;
}

.night_enroll_btn {
    @apply text-night_pale_blue bg-night_primary_blue draw_border
}
.request_enroll_btn{
    @apply text-night_pale_blue border-night_primary_blue border-[2px] text-night_primary_blue draw_border lg:ml-5
}
.feature_segment{
    @apply lg:w-[32.3333%] md:w-[48%] w-full px-4 lg:px-8 py-7 my-2 relative;
    transition: 0.3s;
    img{
        @apply w-[45px] mb-4;
    }
}
.feature_segment_semi{
    @apply md:w-[49.5%] w-full px-4 lg:px-8 py-7 my-2 relative;
    transition: 0.3s;
    img{
        @apply w-[45px] mb-4;
    } 
}
.testimonia_info {
    @apply flex justify-end mt-2;
}
.benefit_side_bubble{
    transform: rotateY(180deg);
}
.next_level_bold{
    @apply  font-semibold;
    font-size: clamp(1.5rem, 3vw, 2rem);
    line-height: 2.4rem;
}
.next_level_article{
    @apply font-[300] text-[17.5px] py-4 opacity-70
}
.hero_bg_wrapper{
    background-image: url('../images//her0_bg.png');
    background-size: cover;
}
.hero_bg_wrapper_customer{
    background-image: url('../images//her0_bg.png');
    background-size: cover;
}
 .label_index{
    @apply border-[3px] border-night_primary_blue
 }
.p_label{
    transition: 2s;
}
 .list_benefit{
    list-style-type: disc;
    padding-left: 1.5rem;
    li{
        font-weight: 300;
    }
 }
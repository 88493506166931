.nav_top_container {
  @apply md:flex items-center lg:flex-row flex-col flex-wrap md:flex-nowrap;

  .logo_container {
    @apply flex items-center justify-between w-full lg:w-auto;

    button {
      @apply lg:hidden
    }
  }

  .links_container {
    @apply block lg:flex w-full lg:w-auto justify-start lg:justify-center
  }

  .links_container {
    @apply justify-end w-full lg:flex block flex-wrap items-center;

    button {
      @apply capitalize mx-4 w-full inline-block lg:w-auto text-left my-2 text-[16px];
      font-weight: 500;
      font-family: "Lexend Exa", sans-serif;
    }
  }
}

@media (max-width: 992px) {
  .nav_button{
    @apply capitalize mx-4 w-full inline-block lg:w-auto text-left my-2 text-[16px];
    font-weight: 500;
    font-family: "Lexend Exa", sans-serif;
  }
}
.waitlist_container_button {
  @apply  text-white py-2 px-4 rounded-lg font-semibold uppercase text-[13px] w-full lg:w-auto whitespace-nowrap
}

.waitlist_button {
  @apply  text-white py-2 px-4 rounded-lg font-semibold text-[13px] uppercase
}

.waitlist_container {
  @apply block w-full lg:w-auto
}
.responsive_nav {
  @apply lg:flex block items-center w-full lg:justify-end overflow-y-hidden lg:overflow-y-auto overflow-x-hidden;
  transition: 0.3s;
}
// .links_container:hover .cursor{
//   transform: scale(3);
//   mix-blend-mode: overlay;
// }
// .links_container button{
//    transition: 0.2s;
// }
// .links_container button:hover {
//    @apply lg:scale-[1.2]
// }
.sign_up_btn{
  @apply px-4 py-2 bg-night_primary_blue text-white rounded-xl w-full lg:w-auto lg:ml-3;
  font-size: 15px;
  font-weight: 600;
  font-family: "Poppins" !important;
}
.book_btn{
  @apply px-6 py-3 bg-night_primary_blue text-white rounded-xl w-full lg:w-auto lg:ml-3;
  font-size: 15px;
  font-weight: 400;
  font-family: "Poppins" !important;
}

.modeCheck{
  -webkit-appearance: none;
  width: 50px;
  height: 25px;
  @apply bg-night_pale_blue;
  border-radius: 50px;
}
.three { width: 45px }
.col {
	display: block;
	float:left;
	margin: 1% 0 1% 1.6%;
}
.hamburger .line{
  width: 30px;
  height: 2px;
  background-color: #ecf0f1;
  display: block;
  margin: 8px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger:hover{
  cursor: pointer;
}
#hamburger-6.is-active{
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
  transition-delay: 0.6s;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#hamburger-6.is-active .line:nth-child(2){
  width: 0px;
}

#hamburger-6.is-active .line:nth-child(1),
#hamburger-6.is-active .line:nth-child(3){
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

#hamburger-6.is-active .line:nth-child(1){
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
}

#hamburger-6.is-active .line:nth-child(3){
  -webkit-transform: translateY(-10px) rotate(90deg);
  -ms-transform: translateY(-10px) rotate(90deg);
  -o-transform: translateY(-10px) rotate(90deg);
  transform: translateY(-10px) rotate(90deg);
}

.checkbox{
  width: 25px;
  height: 25px;
 border-width: 1px;
  border-radius: 50px;
  transition: 0.3s;
  cursor: pointer;
  span{
    @apply inline-block;
    width: 25px;
    height: 25px;
    overflow: hidden;
    border-radius: inherit;
    position: relative;
    transition: 0.3s;
    img{
      border-radius: inherit;
      position: relative;
      top: -1px;
      left: -0.5px;
    }
  }
}

.nav_link_wrapper{
  div{
    @apply my-1 lg:my-0 
  }
}

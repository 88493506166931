.footer_links{
    @apply flex items-center flex-wrap justify-start xl:justify-center;
    button{
        @apply px-3 text-[15px] w-full sm:w-auto text-left py-2 sm:py-0
    }
    button:nth-of-type(1){
        @apply sm:pl-0
    }
}

.side_switch{
    border-radius: 50px;
    @apply bg-night_primary_blue/20 inline-flex relative;
}
.night_club{
    @apply px-0 py-[6px] inline-block cursor-pointer text-center;
    border-radius: inherit;
    width: 123px;
    transition: 0.3s;
    font-weight: 700;
    z-index: 1;
    font-size: 15px;
}
.slide_bg{
    @apply px-0 text-white absolute h-full top-0 left-0;
    z-index: 0;
    border-radius: inherit;
    width: 123px;
    transition: 0.3s;
}
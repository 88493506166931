@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Exa:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Koh+Santepheap:wght@100;300;400;700;900&family=Lexend+Exa:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Koh+Santepheap:wght@100;300;400;700;900&family=Lexend+Exa:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import "tailwind";
@import "navbar";
@import "landing";
@import "footer";
@import "waitlist";

$font-stack: rgb(18 113 227);

::selection {
  background: #39cdcc;
  color: white;
}
* {
  font-family: "Poppins";
}

html,
body {
  overflow-x: hidden !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.linear_wipe {

  background: linear-gradient(to right, rgb(0, 0, 0) 20%, #D3E6F8 40%, #7990a6 50%, rgb(0, 0, 0) 60%);
  background-size: 200% auto;

  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: shine 5s linear infinite;
  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }
}
.article_container {
  transition: 0.5s ease-in-out;
  overflow-y: auto
}
.up_arrow{
  transform: rotateX(0deg);
}
.down_arrow{
  transform: rotateX(180deg);
}

@mixin btn-border-drawing($color: #ccc, $hover: black, $width: 2px, $vertical: top, $horizontal: left, $duration: 0.25s) {
  color: '$color';
  transition: color $duration $duration/3;
  position: relative;
  transition: 0.2s;
  
  &:hover{
    background: transparent;
  }
  &::before,
  &::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0; height: 0;
    
    #{$vertical}: 0; 
    #{$horizontal}: 0;
  }

  &::before {
    $h-side: if($horizontal == 'left', 'right', 'left');
    
    border-#{$vertical}-width: $width;
    border-#{$h-side}-width: $width;
  }
  
  &::after {
    $v-side: if($vertical == 'top', 'bottom', 'top');
    
    border-#{$v-side}-width: $width;
    border-#{$horizontal}-width: $width;
  }
  
  &:hover {
    color: $hover;
     border-radius: 0px;
     box-shadow: inset 0 0 0 $width $color;
    
    &::before,
    &::after {
      border-color: $hover;
      transition: border-color 0s, width $duration, height $duration;
      width: 100%;
      height: 100%;
    }
    
    &::before { transition-delay: 0s, 0s, $duration; }
    
    &::after { transition-delay: 0s, $duration, 0s; }
  }
}
@mixin btn-border-outline($color: #ccc, $hover: black, $width: 2px, $vertical: top, $horizontal: left, $duration: 0.25s) {
  transition: color $duration $duration/3;
  position: relative;
  transition: 0.2s;
  
  &:hover{
    background: transparent;
  }
  &::before,
  &::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0; height: 0;
    
    #{$vertical}: 0; 
    #{$horizontal}: 0;
  }

  &::before {
    $h-side: if($horizontal == 'left', 'right', 'left');
    
    border-#{$vertical}-width: $width;
    border-#{$h-side}-width: $width;
  }
  
  &::after {
    $v-side: if($vertical == 'top', 'bottom', 'top');
    
    border-#{$v-side}-width: $width;
    border-#{$horizontal}-width: $width;
  }
  
  & {
     border-radius: 20px;
     box-shadow: inset 0 0 0 $width $color;
    
    &::before,
    &::after {
      border-color: $hover;
      transition: border-color 0s, width $duration, height $duration;
      width: 100%;
      height: 100%;
    }
    
    &::before { transition-delay: 0s, 0s, $duration; border-radius: 20px; }
    
    &::after { transition-delay: 0s, $duration, 0s; border-radius: 20px; }
  }
}
.draw_border {
  @include btn-border-drawing(rgba(93, 30, 196, 1), rgba(234, 232, 254, 1), 2px, bottom, right);
}
.draw_border_light {
  @include btn-border-drawing(rgba(234, 232, 254, 1), rgba(93, 30, 196, 1), 2px, bottom, right);
}
.draw_border_outline {
  @include btn-border-outline(rgba(93, 30, 196, 1), rgba(93, 30, 196, 1), 2px, bottom, right);
}
.dark_bg_layout{
  @apply relative
}
 .dark_bg_layout{
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 200%;
  height: 100%;
  background-image: linear-gradient(90deg, rgba(17, 13, 33, 1) 50%, rgba(234, 232, 254, 1) 50%);
  transition: 0.2s;
}
.move-left{
  left: -100%
}
.partners_right{
  @apply px-[20px] pt-3;
  img{
    width: 200px
  }
}

.wrap {
  padding: 0 0px;
  color: white;
}

.ticker {
  display: flex;
  align-items: center;
}

.ticker__inner {
  flex: 1;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.ticker__entry {
 width: 300px;
  padding-right: 30px;
  white-space: nowrap;
  img{
     width: 200px
  }
}
.text_feature_header{
 font-size: 18px;
 display: block;
 margin-bottom: 0.7em;
 font-weight: 500;
}
.text_feature_header_waitlist{
  @apply text-[12px] lg:text-[11px] block;
  margin-bottom: 0.7em;
}
.text_feature_small{
  font-size: 14px;
  font-weight: 300;
  opacity: 0.7;
}
.text_feature_small_waitlist{
  @apply text-[10px] lg:text-[9px] block
}
.transit_image_hint{
  transition: height 1s ease; 
}
@media (max-width: 1024px) {
  .max_h_0 {
      max-height: 0;
  }

  .max_h_screen {
      max-height: 100vh;
      /* Or any value that fits your design */
  }
}

.testimonia_box {
  @apply border-night_greg rounded-xl p-3 py-7 lg:px-8 border-[1px] mr-4;
  flex: 0 0 auto;
  transition: 0.3s;
  border-width: 1px;
}
.foot_text{
  font-family: "Poppins" !important;
}

.test_btn{
  @apply  overflow-hidden w-full lg:w-auto cursor-pointer inline-block;
  height: 2.2rem;
  
  span{
      @apply flex w-full h-full lg:justify-center items-center text-white px-3 pl-0 lg:pl-3 relative top-0 text-[16px];
      font-weight: 500;
      transition: 0.4s;
  } b{
      @apply flex w-full h-full lg:justify-center items-center relative  px-3 pl-0 lg:pl-3 text-white lg:ml-0 bg-night_pale_blue/0 top-0 text-[16px] overflow-x-hidden;
      border-radius: 100%;
      font-weight: 500;
      transition: 0.4s;
  }
}
.test_btn:hover .text_replace{
   top: -100%;
   border-radius: 3px;
}
.test_btn:hover .text_place{
  top: -100%
}

.test_btn_light{
  @apply  overflow-hidden w-full lg:w-auto cursor-pointer inline-block;
  height: 2.2rem;
  
  span{
      @apply flex w-full h-full lg:justify-center items-center text-night_blue px-3 pl-4 lg:pl-3 relative top-0 text-[16px];
      font-weight: 500;
      transition: 0.4s;
  } b{
      @apply flex w-full h-full lg:justify-center items-center relative  px-3 pl-4 lg:pl-3 text-night_blue lg:ml-0 bg-night_pale_blue/0 top-0 text-[16px] overflow-x-hidden;
      border-radius: 100%;
      font-weight: 500;
      transition: 0.4s;
  }
}
.test_btn_light:hover .text_replace{
   top: -100%;
   border-radius: 3px;
}
.test_btn_light:hover .text_place{
  top: -100%
}
 .transit{
  transition: 0.3s;
 }
.lexand_font_sm_bold{
  font-family: "Lexend Exa";
  font-weight: 400;
  letter-spacing: -6.3px;
  font-size: clamp(40px, 5vw, 60px);
  line-height: clamp(47px, 5vw, 85px);
}
 .book_btn{
  @apply bg-night_primary_blue text-white py-3 px-6 rounded-lg text-sm
 }
 .book_btn_light{
  @apply  text-white py-3 px-6 rounded-lg text-sm
 }
 
 .roller {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 

.effect-16, 
.effect-17, 
.effect-18{border: 0; padding: 4px 0;  background-color: transparent; width: 100%; outline:  none; position: relative; color: white;
  opacity: 0;
  margin-left: 8px;
  font-size: 15px;
  transition: 0.4s; top: 0}

.effect-16 ~ .focus-border{position: relative; bottom: 14px; left: 0; width: 0; height: 2px; background-color: #4caf50; transition: 0.4s;}
.effect-16:focus ~ .focus-border,
.has-content.effect-16 ~ .focus-border{width: 100%; transition: 0.4s;}
.effect-16 ~ label{position: relative; left: 0; width: 100%; bottom: 35%; color: #aaa; transition: 0.3s; z-index: -1; letter-spacing: 0.5px; font-size: 15px;
padding-left: 0.5rem;
}
.focus_label{bottom: 63% !important; font-size: 12px !important; font-weight: 600; transition: 0.3s;}
.focus_effect{
     top: 18px;
     opacity: 1;
}
 /* If you need the content to be scrollable but want to hide the scrollbar */
.scrollable-hide-scrollbar {
  overflow-y: scroll;
}

/* Hide scrollbar in webkit browsers (like Chrome, Safari) */
.scrollable-hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* For Firefox */
.scrollable-hide-scrollbar {
  scrollbar-width: none; /* Firefox */
}
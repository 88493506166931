.waitlist_title {
    font-weight: 300;
    font-size: clamp(1.1rem, 3vw, 1.4rem);
    @apply mt-8
}

.feature_waitlist {
    @apply lg:w-[32.3333%] md:w-[48%] w-full px-4 lg:px-4 py-7 lg:py-4 my-2 relative;
    transition: 0.3s;

    img {
        @apply w-[35px] mb-4;
    }
}

.feature_waitlist_semi {
    @apply md:w-[49.5%] w-full px-4 lg:px-4 py-7 lg:py-4 my-2 relative;
    transition: 0.3s;

    img {
        @apply w-[35px] mb-4;
    }
}

.form_wrapper {
    @apply bg-night_primary_blue/60 lg:rounded-[2.5rem] rounded-2xl max-w-[550px] w-full backdrop-blur-3xl px-3 lg:py-[2rem] sm:px-[2rem] py-4
}

.club_name {
    @apply w-full bg-white/10 border-[1px] border-white/40 px-3 rounded-2xl  h-[53px] my-8;

    // label {
    //     @apply w-full text-[10px] block
    // }

    // input {
    //     @apply border-none outline-none px-0 py-0 bg-transparent w-full
    // }
}

.club_email {
    @apply w-full bg-white/10 border-[1px] border-white/40 py-2 px-6 rounded-2xl my-8;

    input {
        @apply border-none outline-none px-0 py-2 bg-transparent w-full
    }
}

.club_message {
    @apply w-full my-8;

    textarea {
        @apply bg-white/10 border-[1px] border-white/40 py-2 px-6 rounded-2xl resize-none w-full my-3 h-40 text-white outline-none;
    }

    label {
        @apply w-full text-[12px] block
    }
}

.light_input::placeholder {
    color: black;
    /* Set the desired color here */
}

.dark_input::placeholder {
    color: rgb(188 188 188 / 0.7);
    /* Set the desired color here */
}

.modalContainer {
    @apply fixed w-[100vw] h-[100vh] top-0 left-0 z-40 flex items-center px-3 overflow-y-auto justify-center
}

.modalContainer::before {
    content: "";
    @apply w-full h-[50%] bg-black/70 top-0 left-0 absolute;
    animation: linerTop 0.3s ease-out
}

@keyframes linerTop {
    from {
        height:  0%;
    }

    to {
        height: 50%;
    }
}

.modalContainer::after {
    content: "";
    @apply w-full h-[50%] bg-black/70 bottom-0 left-0 absolute;
    animation: linerBottom 0.3s ease-out;
    z-index: -1;
}

@keyframes linerBottom {
    from {
        height:  0%;
    }

    to {
        height: 50%;
    }
}

.qrcode__section{
    @apply border-2  rounded-[2rem] border-gray-300 flex items-center justify-center flex-wrap
}
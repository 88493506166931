.benefit_titles{
    @apply font-[300] text-[17px] pb-4;
}
.benefit_titles_heads{
    @apply font-[600] py-5
}
.bold_benefit{
    font-size: clamp(35px, 5vw, 70px);
    font-weight: 300;
}
.bold_benefit_desc{
    font-size: 16px;
}